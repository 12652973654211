#intro {
  padding:100px 10px;
  text-align: center;

  h2 {
  	font-size:40px;
  	letter-spacing:-.05em;
  	padding-bottom:30px;
    color:#000;
  }

  h3 {
    display: inline-block;
    font-size: 16px;
    padding:6px 10px;
    background-color: #000;
    color:#fff;
  }

  .icon-play:before {
    position: absolute;
    font-size:1.6em;
    top:-1.05em;
    left:0;
  }

  a {
    font-size:16px;
    text-decoration: none;
    color:#000;
    position: relative;
    display: inline-block;
    clear: both;
    padding:0 0 0 2em;
  }

  @include media($tablet) {
    padding:90px 10px;
    h2 {
      font-size: 60px;
      padding-bottom:40px;
    }
    h3 {
      font-size: 20px;
    }
    a {
      font-size:22px;
    }
  }
  @include media($desktop) {
    padding:130px 30px;
    h2 {
      font-size:80px;
    }
  }
  @include media($full) {
    padding:180px 30px;
    h2 {
      font-size:110px;
    }
  }
  @include media($huge) {
    padding:220px 30px;
  }
}


.section-block {
  display: block;
  clear: both;
  @include clearfix();
  padding-top:60px;
  padding-bottom:60px;
  text-align: center;

  h2 {
    text-transform: uppercase;
    font-size:12px;
    margin-bottom: 30px;
    letter-spacing: 2px;
  }

  p.big-text {
    font-size:22px;
    max-width: 1250px;
    margin:0 auto;
  }

  dl {
    padding:30px 0;
    dt {
      margin-bottom:20px;
      font-size:22px;
      font-weight: bold;
      letter-spacing: -1px;
    }
    dd {
      font-size:14px;
      line-height: 1.6em;
    }

    &:first-child {
      padding-top:0;
    }
  }

  @include media($tablet) {
    padding-top:80px;
    padding-bottom:80px;

    h2 {
      font-size:14px;
    }
    .columns {
      max-width:768px;
      margin:0 auto;
    }
    p.big-text {
      font-size:40px;
    }
    dl {
      width:50%;
      float: left;
      dt {
        font-size: 30px;
      }
      &:first-child {
        padding-top:30px;
      }
    }
  }

  @include media($desktop) {
    padding-top:90px;
    padding-bottom:90px;

    h2 {
      font-size:16px;
      margin-bottom: 40px;
    }
    dl {
      dd {
        font-size:16px;
      }
    }
  }

  @include media($full) {
    padding-top:100px;
    padding-bottom:100px;

    h2 {
      font-size:18px;
    }
    p.big-text {
      font-size:60px;
    }
    dl {
      dt {
        font-size:36px;
      }
      dd {
        font-size:18px;
      }
    }
  }
}

.text-blurb {
  font-size:22px;
  padding-right:15px;
  padding-left:15px;
  line-height: 1.4em;

  @include media($tablet) {
    font-size:40px;
    padding-right:30px;
    padding-left:30px;
  }
}

.banner-full {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  /*background-color: $light-grey;*/
  background-size: cover;
  @include clearfix();
  padding:0 15px;

  video {
    display: block;
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:auto;
  }

  a {
    position: relative;
    display: block;
    width:100%;
    height:100%;
    overflow: hidden;
    
    .scaler-img {
      position: absolute;
      top:0;
      left:50%;
      width:100%;
      height:auto;
      margin-left:-50%;
    }

    .scaler-img, video {
      @include transition(all $default-speed $default-transition);
    }
  }

  img {
    width:100%;
    height:auto;
    display: block;
  }
  .banner-center-text {
    width:100%;
    height:100%;
    position: absolute;
    z-index: 60;

    div {
      text-align: center;
      padding:0 10px;
      @include vertical-align();
      color:#fff;
      @include transition(all $default-speed $default-transition);
    }
    .subtitle {
      font-size:12px;
      text-transform: uppercase;
      letter-spacing:2px;
    }
    h3 {
      font-size:30px;
    }
  }

  @include media($tablet) {
    .banner-center-text {
      .subtitle {
        font-size:14px;
      }
      h3 {
        font-size:40px;
      }
    }
    a:hover {
      cursor: pointer;

      .scaler-img, video {
        @include scale(1.2,1.2);
      }

      .overlay {
        background-color: darken($red,20%);
      }
    }
  }
  @include media($desktop) {
    padding:0;
    /*padding:0 40px;*/

    .banner-center-text {
      .subtitle {
        font-size:16px;
      }
    }
  }
  @include media($full) {
    .banner-center-text {
      .subtitle {
        font-size:18px;
      }
      h3 {
        font-size:60px;
      }
    }
  }
}

.blue {
  .banner-full {
    a:hover {
      .overlay {
        background-color: darken($blue,20%);
      }
    }
  }
}

.green {
  .banner-full {
    a:hover {
      .overlay {
        background-color: darken($green,20%);
      }
    }
  }
}