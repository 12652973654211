#work-archive {
	#ajax-load-posts {
		width:100%;
		background-color: #fff;
		@include clearfix();
	}
	.entry-list-item {
		display: block;
		width:100%;
		float: left;
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
		@include transition(background $default-speed $default-transition);

		.hide-on-mobile {
			display: none;
		}

		.vert-center-content {
			width:100%;
			height:100%;
			position: absolute;
			top:0;
			left:0;
			z-index: 80;
			text-align: center;
			color:#fff;

			h2 {
				display: block;
				width:100%;
				color:#fff;
				text-align: center;
				font-size:40px;
			  	letter-spacing:-.05em;
			  	padding-bottom:10px;

				span {
					display: block;
					padding:0 1em;
				}
			}

			.work-link-excerpt {
				display: inline-block;
			    font-size: 16px;
			    margin:10px;
			    color:#fff;

			    span {
			    	padding:6px 10px;
			    	line-height: 1.4em;
			    	background-color: #000;
			    }
			}

			.work-link-tools {
				padding:10px;
				font-size: 10px;

				li {
					display: inline-block;
					padding:0 4px;
					color:#fff;

					span {
						display: block;
						height:20px;
					}

					.tool-icon {
						display: inline-block;
						width:20px;
						margin-bottom: -5px;
						height:20px;
						@include retina-image('../images/tools-icon', 20px 20px, 'png');
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
			}
		}

		.overlay {
			width:101%;
			height:101%;
			position: absolute;
			top:0;
			left:0;
			z-index: 50;
			overflow: hidden;
			text-align: center;
		}

		@include media($mid-mobile) {

			.hide-on-mobile {
				display: block;
			}

			.hide-on-mid-mobile {
				display: none;
			}
		}

		@include media($tablet) {
			background-size: auto 101%;

			&.half-entry {
				height: auto;
				width:50%;

				.vert-center-content h2 {
					font-size: 30px;
				}
			}

			.vert-center-content {

				.work-link-excerpt {
					font-size: 20px;
				}

				h2 {
					font-size: 40px;
				}
			}

			&:hover {
				background-size: auto 120%;

				.overlay {
					background-color: darken($red,20%);
				}
			}

		}

		@include media($desktop) {

			&.half-entry {
				.vert-center-content h2 {
					font-size: 50px;
				}
			}

			.vert-center-content {

				h2 {
					font-size:80px;
				}

				.work-link-tools {
					font-size: 14px;
				}
			}
		}

		@include media($full) {

			&.half-entry {
				.vert-center-content h2 {
					font-size: 60px;
				}
			}

			.vert-center-content {
			    h2 {
			      font-size:110px;
			    }
			}
  		}
	}

}

@include media($tablet) {
	.blue {
		#work-archive .entry-list-item:hover {
			.overlay {
				background-color: darken($blue,20%);
			}
		}
	}

	.green {
		#work-archive .entry-list-item:hover {
			.overlay {
				background-color: darken($green,20%);
			}
		}
	}
}


#scaled-marquee {
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	/*transition: height $default-speed $default-transition;*/

	img, video {
		display: block;
		width:100%;
		height:auto;

		&.none {
		  display: none;
		}
	}

	video {
		position: absolute;
		top:0;
		left:0;
		background-color: #fff;
	}

	.scaled-marquee-title {
		position: absolute;
		top:50%;
		left:0;
		right:0;
		width:100%;
		z-index: 70;
		margin-top:-.75em;
		padding:0 40px;
		line-height: 1.2em;
		text-align: center;
		font-size:40px;
		color:#fff;
		letter-spacing:-.05em;
	}

	#work-marquee-video-link {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		text-align: center;
		height: 16px;
		line-height: 16px;
		font-size:16px;
	}

	.video-modal-link {
		display: inline-block;
		text-decoration: none;
		color:#fff;
		padding:16px 50px 14px 20px;
		@include retina-image('../images/external-link', 45px 30px, 'png');
		background-position: center right;
		background-repeat: no-repeat;
		@include transition(background-color $default-speed $default-transition);
	}

	@include media($mid-mobile) {
		img.hide-on-mid-mobile {
		  display: none;
		}
		img.show-on-mid-mobile {
			display: block;
		}
	}

	@include media($tablet) {
		height: 80vh;

		video {
			display: block !important;
		}

		.scaled-marquee-title {
			/*max-width: 75%;
			margin-right:auto;
			margin-left:auto;*/
			padding:0 200px;
			font-size: 60px;
		}

		.video-modal-link:hover {
			background-color:#fff;
			color:#000;
			@include retina-image('../images/external-link-hover', 45px 30px, 'png');
		}
	}

	@include media($desktop) {
		.scaled-marquee-title {
			font-size:80px;
		}
	}

	@include media($full) {
		.scaled-marquee-title {
			font-size:110px;
		}
	}
}

.work-marquee-prev-link, .work-marquee-next-link {
	display: block;
	position: absolute;
	color: #fff;
	height: 200px;
	width: 30px;
	top:50%;
	margin-top:-100px;
	z-index: 90;
	background-position: center center;
	background-repeat: no-repeat;

	div {
		display: none;
	}

	@include media($tablet) {

		&:hover {
			cursor: pointer;

			div span {
				background-color: #fff;
				color:#000;
			}
		}

		div span {
			text-transform: uppercase;
			display: inline-block;
			padding:10px;
			border:6px solid transparent;
			@include transition(background-color $default-speed $default-transition, border $default-speed $default-transition);
		}
	}

	@include media($desktop) {
		div {
			display: block;
			width:200px;
			top:80px;
			position: absolute;
			text-align: center;
			white-space: nowrap;
		}
	}
}

.work-marquee-prev-link {
	left:10px;
	@include retina-image('../images/prev-icon', 15px 25px, 'png');

	@include media($desktop) {
		left:30px;

		div {
			left:0px;
		    transform: rotate(-90deg);
		    transform-origin: center center;
		}
	}

	@include media($full) {
		left:60px;
	}
}

.work-marquee-next-link {
	right:10px;
	@include retina-image('../images/next-icon', 15px 25px, 'png');

	@include media($desktop) {
		right:30px;

		div {
			right:0px;
			transform: rotate(90deg);
		    transform-origin: center center;
		}
	}

	@include media($full) {
		right:60px;
	}
}


#work-mockups {
	padding:15px;
	background-color:$super-light-grey;
	@include row();

	.work-mockup {
		position: relative;
		display: block;
		width:100%;
		height: auto;
		margin-bottom:10px;
		overflow: hidden;

		&:nth-child(2n+1) {
			clear:both;
		}

		&:last-child {
			margin-bottom: 0;
		}

		img {
			display: block;
			width:100%;
			height:auto;
		}
	}

	.work-mockup-details {
		display: none;
		position: absolute;
		bottom:0;
		left:0;
		background-color: #000;
		color:#fff;
		margin:0;
		padding:8px 10px;

		.work-mockup-title {
			font-size: 14px;
		}

		.work-mockup-caption {
			font-size: 12px;
			line-height: 1.4em;
		}
	}

	@include media($tablet) {
		padding-right:10px;
		padding-left:10px;

		.work-mockup {
			width:50%;
			float: left;
			padding:0 5px;

			&:nth-last-child(2n) {
				margin-bottom: 0;
			}

			&:hover {
				.work-mockup-details {
					bottom:0px;
				}
			}
		}

		.work-mockup-details {
			bottom:-80px;
			margin:0 5px;
			@include transition(bottom $default-speed $default-transition);
		}
	}

	@include media($huge) {
		padding-right:0px;
		padding-left:0px;
	}
}

.work-tools {
	text-align: center;
	padding:30px 10px;

	/*.work-tools-title {
		font-size:22px;
	}*/

	.work-tools-list {
		list-style: none;
		margin:30px 0 0;
		font-size: 12px;

		li {
			display: inline-block;
			background-color: #000;
			color:#fff;
			padding:5px 8px;
			margin:0 5px 10px;
			line-height: 1em;
		}
	}

	@include media($tablet) {
  		padding:60px 80px;
  	}

  	@include media($desktop) {
  		padding:100px 100px;
  	}
}