#top-wrap {
  position: absolute;
  width:100%;
  top:0;
  left:0;
  z-index: 200;
  background-color: transparent;
  color:#000;
  transition: top $default-speed $default-transition,background-color $default-speed $default-transition;

  .wrap {
    height:32px;
    margin:18px auto;
    padding:0 10px;
    max-width: 1920px;

    @include media($desktop) {
      height:38px;
      margin:30px auto;
      padding:0 30px;
    }

    @include media($full) {
      padding:0 40px;
    }
  }

  a {
    color:#000;
    text-decoration: none;
    transition: color $default-speed $default-transition;
  }

  .home-link:hover {
    color:#000;
  }

  #nav-toggle {
    display: block;
    width:30px;
    height:25px;
    padding:15px 0;
    position: absolute;
    bottom:2px;
    right:15px;

    span, span:before, span:after {
      cursor:pointer;
      height: 2px;
      width:  30px;
      background: #000;
      position: absolute;
      display: block;
      content: '';
      transition: all $default-speed/2 $default-transition;
    }
    span:before {
      top: -8px;
    }
    span:after {
      bottom: -8px;
    }
    &:hover {
      cursor: pointer;
      span, span:before, span:after {
        background-color:$grey;
      }
    }
  }

  #main-nav {
    display: none;
    text-align: center;
    font-size:36px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .menu-color-selector {
    display: none;
    position: absolute;
    bottom:-5px;
    right:0;
    margin-bottom:12px;
    margin-right:40px;
    background-color: #fff;
    padding:5px 6px;

    div {
      display: inline-block;
      float: left;
      width:10px;
      height:10px;
      margin-left:5px;
      border:1px solid #000;
      border-radius: 50%;

      &:first-child {
        margin-left:0;
      }

      &:hover {
        cursor:pointer;
        border-color:#000;
      }

      &.active {
        border-radius: 0px;
      }

      &.red-selector {
        background-color: $red;
      }

      &.green-selector {
        background-color: $green;
      }

      &.blue-selector {
        background-color: $blue;
      }

      span {
        display: none;
      }
    }
  }

  @include media($desktop) {

    a:hover, a.active {
      color:$red;
    }
    #nav-toggle {
      display: none !important;
    }
    #main-nav {
      font-size:16px;
      display: block !important;
      padding:0;
      position: absolute;
      top:0;
      left:0;
      width:100%;
      ul {
        position: relative;
        li {
          display: inline-block;
          margin-top:15px;
          a {
            display: block;
            padding:0 35px;
            color:#000;
          }
        }
      }
      .hide-on-desktop {
        display: none;
      }
      #link-contact {
        display: none !important;
      }
    }

    .menu-color-selector {
      display: block;
    }
  }

  @include media($full) {
    #main-nav {
      font-size:18px;
      ul li a {
        display: block;
        padding:0 40px;
      }
    }
  }

}

.white-nav {
  #top-wrap {

    #main-nav {
      ul li a {
        color:#fff;
      }
    }
    
    #nav-toggle {
      span, span:before, span:after {
        background-color:#fff;
      }
    }
  }
}

#logo {
  display: block;
  position: relative;
  z-index: 250;
  width:40px;
  height:40px;
  background-repeat: no-repeat;
  @include retina-image('../images/inrgb-logo', 40px 40px, 'png');

  a {
    display: block;
    width:100%;
    height:100%;
    position: relative;

    span {
      display: none;
    }
  }

  @include media($desktop) {
    position: absolute;
  }
}

.nav-open {
  overflow: hidden;

  #top-wrap, &.white-nav #top-wrap {
    background-color: transparent;
    height:100%;
    position: fixed;
    overflow: hidden;

    &.nav-up, &.nav-down {
      top:0 !important;
    }

    &.fadein {
      background-color: rgba(0,0,0,.9);
      #main-nav ul {
        @include opacity(1);
      }
    }

    .wrap {
      height: 100%;
    }
    
    #nav-toggle {
      bottom:auto;
      top:2px;
      span, span:before, span:after {
        background-color:#fff;
      }
      span {
        background-color: transparent;
      }
      span:before, span:after {
        top: 0;
      }
      span:before {
        transform:rotate(45deg);
      }
      span:after {
        left:0;
        width: 30px;
        transform:rotate(-45deg);
      }
    }
  }
  #main-nav, &.white-nav #main-nav {
    display: block !important;
    position: absolute;
    top:50%;
    left:0;
    width:100%;
    margin-top:-3em;
    ul {
      display: block;
      width:100%;
      @include opacity(0);
      transition: opacity $default-speed $default-transition;

      li a {
        color:#fff;
        display: block;
        padding:10px 0;
      }
    }
  }
  @include media($desktop) {
    overflow: visible;

    #top-wrap, &.white-nav #top-wrap {
      height:auto;
      background-color: transparent;
      position: absolute;

      &.fadein {
        background-color: transparent;
      }

      .wrap {
        height:38px;
      }
    }
    #main-nav, &.white-nav #main-nav {
      margin-top:0;

      ul {
        width:auto;
        li a {
          color:#000;
        }
      }
    }
  }
}