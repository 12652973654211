#footer {
	/*margin-top:40px;*/
	background-color: $super-light-grey;
	text-align: center;
	@include clearfix();

	/*@include media($tablet) {
		margin-top:60px;
	}*/
	@include media($huge) {
		margin-top:100px;
	}
}

#footer-contact-link {
	width:100%;
	text-align: center;
	font-size:22px;
	
	a {
		display: block;
		background-color: #fff;
		padding:60px 15px;
		text-decoration: none;
		color:#000;
		border-top:2px solid $super-light-grey;
		@include transition(background-color $default-speed $default-transition, color $default-speed $default-transition);

		.underlined {
			color:$red;
			text-decoration: underline;
			@include transition(color $default-speed $default-transition);
		}
	}

	@include media($tablet) {
		font-size: 40px;
		a {
			padding:80px 15px;

			&:hover {
				background-color: $red;
				color:#fff;
				border-color:$red;

				.underlined {
					color:#fff;
				}
			}
		}
	}
	@include media($full) {
		font-size: 60px;
		a {
			padding:100px 15px;
		}
	}
}

.footer-cols {
	padding:30px 0;
	@include clearfix();

	.col {
		position: relative;
		color:$grey;

		&.middle-col {
			padding:20px 0;
			margin:20px 0;
			border-top:1px solid $light-grey;
			border-bottom:1px solid $light-grey;
		}

		.content-block-inner {
			padding:30px 10px;
		}

		h2 {
			margin-bottom:30px;
			font-size:22px;
			letter-spacing:1px;
		}

		.med-copy {
			letter-spacing: 1px;
			font-size:14px;
			line-height: 20px;
			font-weight:500;
			display: block;
			max-width: 300px;
			margin:0 auto;
			clear: both;
		}

		.footer-cta-link {
			margin-top:30px;
			display: inline-block;
			clear: both;
			color:$grey;
			text-transform: uppercase;
			padding:10px 20px;
			font-size:12px;
			letter-spacing: 2px;
			font-weight: 500;
			background-color:$light-grey;
			text-decoration: none;
			@include transition(background-color $default-speed $default-transition, color $default-speed $default-transition);
		}

		@include media($tablet) {
			width:33.3333%;
			float: left;

			&.middle-col {
				padding:0;
				margin:0;
				border-top:none;
				border-bottom:none;
			}

			.footer-cta-link {
				&:hover {
					background-color:#000;
					color: #fff;
				}
			}
		}

		@include media($desktop) {
			.med-copy {
				font-size:16px;
				line-height:24px;
			}
		}

		@include media($full) {
			h2 {
				font-size: 26px;
			}
			.footer-cta-link {
				font-size: 14px;
			}
		}
	}

	@include media($tablet) {
		padding:50px 0;
	}
	@include media($full) {
		padding:70px 0;
	}
}

#footer-copyright {
	padding:30px;
	font-size:10px;
	position: relative;

	.copy-text {
		color:$semi-light-grey;
		display: block;
		@include clearfix();
	}
}

#footer-social {
	list-style: none;
	position: relative;
	font-size:30px;
	margin-top:30px;
	@include clearfix();
	overflow: hidden;

	li {
		display: inline-block;
		width:30px;
		height:30px;
		position: relative;
		a {
			display: block;
			width:100%;
			height:100%;
			position: absolute;
			top:0;
			left:0;
			color:#000;

			span {
				&:before {
					display: block;
					position:absolute;
					top:-25px;
					left:0;
				}
			}

			&:hover {
				color:$red;
			}
		}
	}
}