// @see https://www.diffchecker.com/jz7tWb0v

@mixin vp-display($value) {
  @if $enable-autoprefixer {
    @if $value == flex {
      @include vp-flexbox();
    }
    @if $value == inline-flex {
      @include vp-inline-flex();
    }
  } @else {
    display: $value;
  }
}

@mixin vp-position($value) {
  @if $enable-autoprefixer {
    @if $value == sticky {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      //position: -o-sticky;
    }
  }
  position: $value;
}

@mixin vp-overflow($value) {
  @if $enable-overflow-scrolling {
    -webkit-overflow-scrolling: touch;
  }
  overflow: $value;
}

@mixin vp-overflow-x($fmt) {
  @if $enable-overflow-scrolling {
    -webkit-overflow-scrolling: touch;
  }
  overflow-x: $value;
}

@mixin vp-overflow-y($value) {
  @if $enable-overflow-scrolling {
    -webkit-overflow-scrolling: touch;
  }
  overflow-y: $value;
}

@mixin vp-box-sizing($value) {
  @if $enable-autoprefixer {
    -webkit-box-sizing: $value;
       -moz-box-sizing: $value;
  }
  box-sizing: $value;
}

@mixin vp-box-shadow($value) {
  @if $enable-autoprefixer {
    -webkit-box-shadow: $value;
  }
  box-shadow: $value;
}

@mixin vp-touch-action($value) {
  @if $enable-autoprefixer {
    -ms-touch-action: $value;
  }
  touch-action: $value;
}

@mixin vp-user-select($value) {
  @if $enable-autoprefixer {
    -webkit-user-select: $value;
      -moz-user-select: $value;
        -ms-user-select: $value;
  }
  user-select: $value;
}

@mixin vp-column-count($value) {
  @if $enable-autoprefixer {
    -webkit-column-count: $value;
       -moz-column-count: $value;
  }
  column-count: $value;
}

@mixin vp-column-gap($value) {
  @if $enable-autoprefixer {
    -webkit-column-gap: $value;
       -moz-column-gap: $value;
  }
  column-gap: $value;
}

@mixin vp-appearance($value) {
  @if $enable-autoprefixer {
	  -webkit-appearance: $value;
       -moz-appearance: $value;
  }
  appearance: $value;
}

@mixin vp-transform($value...) {
  @if $enable-autoprefixer {
	  -webkit-transform: $value;
      -ms-transform: $value;
       //-o-transform: $value;
  }
  transform: $value;
}

@mixin vp-backface-visibility($value) {
  @if $enable-autoprefixer {
	  -webkit-backface-visibility: $value;
  }
  backface-visibility: $value;
}

@mixin vp-perspective($value) {
  @if $enable-autoprefixer {
	  -webkit-perspective: $value;
  }
  perspective: $value;
}
