// Change the grid settings
$column: 50px;
$gutter: 20px;
$grid-columns: 12;

// Define your breakpoints
$mid-mobile: new-breakpoint(min-width 480px);
$tablet: new-breakpoint(min-width 768px);
$desktop: new-breakpoint(min-width 960px);
$full: new-breakpoint(min-width 1200px);
$huge: new-breakpoint(min-width 1920px);


$default-speed: .5s;
/*$default-transition: cubic-bezier(.09,.5,.35,.98);*/
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$default-transition: $easeOutCubic;
$aggressive-transition: $easeInOutQuint;

$super-light-grey: #f8f8f8;
$semi-light-grey: #a5a6a5;
$light-grey: #e6e7e8;
$grey: #818f93;
$dark-grey: #34383a;
$med-dark-grey: #141615;
$super-dark-grey: #1b1e1f;
$greyText: #959999;
$red: #eb2123;
$green: #69bb45;
$blue: #3952a2;

@mixin fuck-smoothing() {
  image-rendering: optimizeSpeed;             /* FUCK SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges;          /* Firefox                        */
  image-rendering: -o-crisp-edges;            /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor;   /* IE8+    */
}

@mixin opacity($value,$important:'') {
  $IEValue: $value*100;

  @if $important != '' {
    -moz-opacity: $value $important;
    -webkit-opacity: $value $important;
    opacity: $value $important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")" $important;
    filter: alpha(opacity=$IEValue) $important;
  }
  @else
  {
    -moz-opacity: $value;
    -webkit-opacity: $value;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    filter: alpha(opacity=$IEValue);
  }
  zoom: 1; /* goddamn ie */
}

@mixin enableHardwareAcceleration() {
  -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  /*-webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;*/
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin scale($x,$y,$speed:$default-speed,$ease:$default-transition) {
  -ms-transform: scale($x,$y); /* IE 9 */
  -webkit-transform: scale($x,$y); /* Chrome, Safari, Opera */
  -moz-transform: scale($x,$y); /* Firefox */
  transform: scale($x,$y);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin box-shadow($ho,$vo,$blur,$spread,$color) {
  -webkit-box-shadow: $ho $vo $blur $spread $color;
  -moz-box-shadow: $ho $vo $blur $spread $color;
  box-shadow: $ho $vo $blur $spread $color;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}