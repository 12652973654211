@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

@font-face {
    font-family: 'fancy-font';
    src: url('../fonts/fancy-font-webfont.woff2') format('woff2'),
         url('../fonts/fancy-font-webfont.woff') format('woff'),
         url('../fonts/fancy-font-webfont.ttf') format('truetype'),
         url('../fonts/fancy-font-webfont.svg#fancy-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

html,body {
  height: 100%;
}

body {
  font-style: normal;
  font-size:100%;
  font-weight: normal;
  font-family: 'Karla', Helvetica, Arial, sans-serif;
  position: absolute;
  width:100%;
  color:$dark-grey;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

.none {
  display: none;
}

.fancy-font {
  font-family: 'fancy-font';
}

.wrap {
  position: relative;
  margin:0 auto;
  max-width: 1920px;
}

.wrap-small {
  position: relative;
  margin:0 auto;
  max-width:1360px;
}

a {
  color:#000;
  transition: color $default-speed $default-transition;
  @include media($desktop) {
    &:hover {
      color:#777;
    }
  }
}

.red-text {
  color: $red;
}

.green-text {
  color:$green;
}

.blue-text {
  color:$blue;
}
@keyframes popIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    /*margin-top: 10%;*/
    opacity: 0;
  }
  100% {
    /*margin-top:0;*/
    opacity: 1;
  }
}

@include keyframes(rgbAnimateBg) {
  0% {
    background-color: $red;
  }
  33% {
    background-color: $green;
  }
  66% {
    background-color: $blue;
  }
  100% {
    background-color: $red;
  }
}

@include keyframes(rgbAnimateColor) {
  0% {
    color: $red;
  }
  33% {
    color: $green;
  }
  66% {
    color: $blue;
  }
  100% {
    color: $red;
  }
}

.rgb-animate-bg-color {
  background-blend-mode: screen;
  mix-blend-mode: screen;
  @include animation('rgbAnimateBg 10s infinite');
}

.rgb-animate-color {
  @include animation('rgbAnimateColor 10s infinite');
}

.spacer-img, .fluid-img {
  display: block;
  width:100%;
  height:auto;
}

.overlay {
  display: block;
  float: left;
  width:100%;
  height:100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 50;
  background-color:$red;
  @include transition(background-color $default-speed $default-transition, opacity $default-speed $default-transition);
  @include retina-image('../images/dots', 3px 3px, 'png');
  opacity: .9;

  @supports(mix-blend-mode:multiply) {
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    opacity: 1;
  }

  &.green-overlay {
    background-color:$green;
  }

  &.blue-overlay {
    background-color:$blue;
  }
}

.blue .overlay {
  background-color: $blue;
}

.green .overlay {
  background-color: $green;
}

.vert-center-content {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  align-items:center;
  height:100%;
  width:100%;

  .vert-center-content-item {
    width:100%;
  }
  
  .vert-center-content-top {
    align-self: flex-start;
  }

  .vert-center-content-bottom {
    align-self: flex-end;
  }
}

.ie-alert {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  z-index: 99999;
  background-color:#000;
  color:#fff;
  padding:5px 8px;

  a {
    color:#fff;
  }
}
