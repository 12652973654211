@mixin vp-placeholder($color, $opacity: 1) {
  @if $enable-autoprefixer {
    &::-webkit-input-placeholder {
      color: $color;
      opacity: $opacity;
    }
    &::-moz-placeholder {
      color: $color;
      opacity: $opacity;
    }
    &:-ms-input-placeholder {
      color: $color;
      opacity: $opacity;
    }
  }
  &::placeholder {
    color: $color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: $opacity;
  }
}