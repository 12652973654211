
/*// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
*/

/*// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";*/

@import "node_modules/sass-autoprefixer/scss/prefixes";

@import "node_modules/bourbon/app/assets/stylesheets/bourbon";
@import "node_modules/bourbon-neat/app/assets/stylesheets/neat";

/*@import "node_modules/jquery-fancybox/source/scss/jquery.fancybox.scss";*/

@import "variables";
@import "reset";
@import "general";
@import "header";
@import "home";
@import "about";
@import "work";
@import "footer";
@import "fancybox";