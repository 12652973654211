.entry-content {
	padding:30px 15px;
	font-size: 14px;
	line-height: 1.6em;

	h2 {
		text-transform: uppercase;
	    font-size:12px;
	    margin: 50px 0 30px;
	    letter-spacing: 2px;
	    color:$greyText;

	    &.first {
	    	margin-top:0;
	    }
	}

	p {
		margin-bottom: .75em;

		&:last-child {
			margin-bottom:0;
		}
	}

	@include media($tablet) {
		font-size: 18px;
		padding:60px 15px;

		h2 {
      		font-size:14px;
    	}
	}

	@include media($desktop) {
		font-size: 22px;
		padding:90px 40px;

		h2 {
			font-size:16px;
			margin: 60px 0 40px;
    	}
	}

	@include media($full) {
		h2 {
    		font-size:18px;
    	}
	}
}